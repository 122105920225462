<template>
  <section>
    <EquipmentMapPanel
      v-bind:equipmentList="equipmentList"
      v-bind:search="search"
      v-bind:display="display"
      v-on:pick="showEquipmentWithId"
      v-if="isConfigurationReady"
    >
    </EquipmentMapPanel>
  </section>
</template>

<script>
import EquipmentSearchBase from "@/views/private/EquipmentSearchBase.vue";
import EquipmentMapPanel from "@/components/equipment-map-panel.vue";
export default {
  name: "EquipmentSearchMap",
  extends: EquipmentSearchBase,
  components: {
    EquipmentMapPanel
  },
  props: {
    display: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  methods: {
    showEquipmentWithId: function(value) {
      var equipment = this.$store.getters.equipmentById(value);
      if (equipment) {
        this.$router.push("/dashboard/equipment/" + value);
      }
    }
  },
  created: function() {
    var self = this;
    self.$nextTick(function() {
      $('a[data-toggle="tab"]').on("shown.bs.tab", function(evt) {
        self.search = !(evt.target.href.indexOf("equipment-map") > 0);
      });
    });
  },
  beforeDestroy: function() {},
  mounted: function() {}
};
</script>

<style scoped>
.tab-search {
  padding-bottom: 200px !important;
}
.equipment-item {
}
.equipment-item:hover {
  cursor: pointer;
}
.equipment-item .info-box {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}
.equipment-item .info-box:hover {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 1);
  cursor: pointer;
}
.equipment-enabled {
  opacity: 1;
}
</style>
